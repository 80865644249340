<template>
	<div id="main-chart"></div>
	<!-- <div class="client-name">Client Name</div>
  <div class="percent">%</div> -->
</template>

<script>
import * as echarts from "echarts";
import { ref, onMounted } from "vue";
export default {
	emits: ["finished"],
	setup(props, { emit }) {
		function init() {
			const dom = document.getElementById("main-chart");
			const myChart = echarts.init(dom);

			const barSize = ref(55);
			const detailFontSize = ref(90);

			if (window.innerWidth < 400) detailFontSize.value = 40;
			else if (window.innerWidth < 600) detailFontSize.value = 70;

			if (window.innerWidth < 400) barSize.value = 30;
			else if (window.innerWidth < 600) barSize.value = 40;

			const option = ref({
				series: [
					{
						type: "gauge",
						// center: ['40%', '50%'],
						startAngle: 240,
						endAngle: -60,
						min: 0,
						max: 100,
						splitNumber: 12,
						textStyle: {
							color: "red",
						},
						progress: {
							roundCap: true,
							show: true,
							width: barSize.value,
							itemStyle: {
								color: {
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "#44CBAA", // color top
										},
										{
											offset: 1,
											color: "#53aa6c", // color bottom
										},
									],
								},
							},
						},
						pointer: {
							show: false,
						},
						axisLine: {
							roundCap: true,
							lineStyle: {
								color: [[1, "#162F3F"]],
								width: barSize.value,
							},
						},
						axisTick: {
							splitNumber: 3,
							length: 2,
							distance: 20,
							lineStyle: {
								width: 2,
								color: "#53aa6c",
							},
						},
						splitLine: {
							distance: 1000000000,
							length: 3,
							lineStyle: {
								width: 3,
								color: "auto",
							},
						},
						detail: {
							valueAnimation: true,
							offsetCenter: [0, "-10%"],
							// offsetCenter: ["-10%", "-5%"],
							fontSize: detailFontSize.value,
							formatter: "{value}%",
							// color: "red",
						},
						data: [
							{
								value: 94,
								name: "Client retention rate",
								detail: {
									fontFamily: "Archivo",
									color: "#FFF",
								},
							},
						],
					},
				],
			});

			if (option.value && typeof option.value === "object") {
				myChart.setOption(option.value);
			}

			myChart.on("finished", () => {
				emit("finished");
			});

			return myChart;
		}

		onMounted(() => {
			const myChart = init();

			window.onresize = function () {
				if (window.innerWidth > 700) myChart.resize();
				try {
					init();

					// eslint-disable-next-line no-empty
				} catch (error) {}
			};
		});
	},
};
</script>

<style lang="scss">
#main-chart {
	display: flex;
	justify-content: center;
	width: 82%;
	height: 82%;

	canvas {
		margin-left: -130px !important;
	}
}

@media (width <= 700px) {
	#main-chart {
		transform: scale(1.1);

		canvas {
			margin-left: -15em !important;
		}
	}
}

@media (width <= 500px) {
	#main-chart {
		transform: scale(1.7);

		canvas {
			margin-left: -5.5em !important;
		}
	}
}

// .client-name {
//   position: absolute;
//   bottom: 250px;
//   font-size: 16px;
//   color: #ffffff9e;
// }
// .percent {
//   position: absolute;
//   bottom: 300px;
//   left: calc(50% + 25px);
//   top: calc(50% + 10px);
//   font-size: 40px;
//   color: #fff;
// }
</style>
