import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./i18n";
import { createPinia } from "pinia";
import InlineSvg from "vue-inline-svg";
import { createHead } from "@unhead/vue";

const pinia = createPinia();

const app = createApp(App);
const head = createHead();
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(head);
// @ts-ignore
// app.use(VueReCaptcha, { siteKey: "6Ld7fGofAAAAAGCIVuex11O09_SZi1luOBqATcK6" });
app.component("InlineSvg", InlineSvg);

router.isReady().then(() => app.mount("#app"));
