//OBSERVING AND ANIMATION ALL ELEMENT INSIDE GIVEN ELEMENT
//FUNCTION ARGUMENTS
//-rootElementIdentifyer is unique class or id of element which content we want to animate "Make sure that u passed element with UNIQUE ident"
//-animateElements is array of classes id or even tags of element inside that root node
//-enterAnimation & leaveAnimation animation css shorthand read https://developer.mozilla.org/en-US/docs/Web/CSS/animation

const observerConfig = {
	//parent element viewport
	root: null,
	//% of element
	threshold: 0,
	//margins
	rootMargin: "-100px 50% -5% 50%",
};

export default function animateElementsOnScroll(
	rootElementIdentifyer,
	enterAnimation,
	animateElements = "*",
) {
	const rootEl = document.querySelector(rootElementIdentifyer);
	const elementsToAnimate = rootEl.querySelectorAll(animateElements);
	elementsToAnimate.forEach((element) => {
		element.classList.add("hidden");
	});
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			const el = entry.target;
			// console.log(111, el, elementsToAnimate);
			if (entry.isIntersecting) {
				el.style.animation = enterAnimation;
				el.classList.remove("hidden");
				el.addEventListener(
					"animationend",
					() => {
						el.classList.remove("hidden");
						el.style.animation = "";
					},
					{ once: true },
				);
				observer.unobserve(entry.target);
			}
		});
	}, observerConfig);
	elementsToAnimate.forEach((element) => {
		observer.observe(element);
	});
}
