<template>
	<div class="section-template">
		<div
			v-if="data.isBlack"
			class="black-bg"
			:style="[
				data.isBlackBgRight
					? {
							'background-image': ` url(${blackRight}) !important`,
						}
					: {
							'background-image': `url(${blackLeft})`,
						},
			]"
		>
			<inline-svg class="glow-img main" :src="glowImg"></inline-svg>
			<div class="blacksection">
				<img class="blackbadge" :src="data.badgeImgUrl" alt="" />

				<div class="text">
					<h3>
						<div>{{ data.title }}</div>
						<div v-if="data.secondTitle">{{ data.secondTitle }}</div>
					</h3>
					<h4>{{ data.sub }}</h4>
					<p v-for="text in data.paragraphs" :key="text">{{ text }}</p>
				</div>
				<div class="blackimgwrap">
					<inline-svg class="glow-img" :src="glowImg"></inline-svg>
					<img :src="data.mainImgUrl" alt="" />
				</div>
			</div>
		</div>
		<div v-else class="white-bg">
			<div
				class="whitesection"
				:style="{
					'background-image': 'url(' + data.bcgImageUrl + ')',
				}"
			>
				<img class="whitebadge" :src="data.badgeImgUrl" alt="" />
				<div class="whiteimgwrap">
					<img :src="data.mainImgUrl" alt="" />
				</div>
				<div class="whitetext">
					<h3>{{ data.title }}</h3>
					<h4>{{ data.sub }}</h4>
					<p v-for="text in data.paragraphs" :key="text">{{ text }}</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import blackLeft from "@/assets/images/upgraded/project-black-bg-left.png";
import blackRight from "@/assets/images/upgraded/project-black-bg-right.png";
import glowImg from "@/assets/images/upgraded/glow.svg";

// eslint-disable-next-line no-undef
const props = defineProps({
	data: {
		isBlack: Boolean,
		isBlackBgRight: Boolean,
		title: String,
		paragraphs: [String],
		mainImgUrl: String,
		badgeImgUrl: String,
		bcgImageUrl: String,
	},
});
</script>
<style scoped lang="scss">
.section-template {
	.bgright {
		background-image: url("@/assets/images/upgraded/project-black-bg-right.png") !important;
	}

	.glow-img {
		position: absolute;
		margin: auto;
		transform: translateY(-50%);
		top: 50%;
		max-width: 100%;
	}

	&.first {
		.black-bg {
			.glow-img.main {
				top: 0%;
			}
		}
	}

	width: 100%;

	.black-bg,
	.white-bg {
		height: 100%;
	}

	.black-bg {
		min-height: var(--project-section-min-height);
		background-image: url("@/assets/images/upgraded/project-black-bg-left.png");
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;

		// padding-top: 5rem;
		display: flex;
		justify-content: center;

		.blacksection {
			padding: 0 20px;
			display: flex;
			gap: 50px;
			justify-content: space-between;
			max-width: $content-max-width;
			width: 100%;

			.blackbadge {
				position: absolute;
				left: 20px;
				top: 90px;
				max-width: 250px;
				max-height: 100px;
				transition-duration: 0.3s;

				// &:hover {
				// 	transform: scale(0.9);
				// }

				z-index: 1;
			}

			.text {
				flex-grow: 1;
				max-width: 640px;
				min-width: 300px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 2rem;

				h3 {
					font-size: 28px;
					margin-bottom: 0;
					font-weight: 600;
					line-height: 35px;

					@media screen and (width > 768px) {
						display: inline-flex;
						gap: 5px;
						line-height: unset;
					}
				}

				h4 {
					color: $subtext-color;
					font-weight: 500;
					font-size: 24px;
					margin-bottom: 3rem;
				}

				p {
					font-size: 15px;
					line-height: 1.8;
					font-weight: 400;
					margin-bottom: 1.2rem;
				}

				@media screen and (width >768px) {
					h4 {
						font-size: 25px;
					}

					p {
						font-size: 18px;
					}
				}
			}

			.blackimgwrap {
				display: grid;
				place-items: center;
				margin-bottom: 2rem;
				max-width: 750px;

				img {
					max-height: 90%;
					width: 100%;

					// max-width: 90%;
					transition-duration: 0.3s;
				}

				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}

			@media screen and (width <= 768px) {
				flex-direction: column;

				.blackbadge {
					top: 50px;
				}
			}
		}
	}

	.white-bg {
		width: 100%;
		min-height: var(--project-section-min-height);
		display: flex;
		justify-content: center;
		background-color: var(--es-background-color-dirty-white);
		color: var(--es-text-color-black);

		// padding: 2rem 0;
		.whitesection {
			max-width: $content-max-width;
			width: 100%;
			display: flex;
			gap: 50px;

			// flex-flow: row wrap-reverse;
			justify-content: space-between;
			background-repeat: no-repeat;
			background-size: auto;
			background-position: center;
			padding: 2rem 20px 0;

			.whitebadge {
				position: absolute;
				right: 20px;
				top: 90px;
				max-height: 200px;
				max-width: 400px;
				aspect-ratio: auto;
				transition-duration: 0.3s;

				// &:hover {
				// 	transform: scale(0.9);
				// }
			}

			.whitetext {
				max-width: 640px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 2rem;
				color: $section-dark-text;

				h3 {
					font-size: 28px;
					margin-bottom: 0;
					font-weight: 600;
				}

				h4 {
					color: $subtext-color;
					font-weight: 500;
					font-size: 24px;
					margin-bottom: 3rem;
				}

				p {
					font-size: 15px;
					line-height: 1.8;
					font-weight: 400;
					margin-bottom: 1.2rem;
					color: $section-color;
				}

				@media screen and (width >768px) {
					h4 {
						font-size: 25px;
					}

					p {
						font-size: 18px;
					}
				}
			}

			.whiteimgwrap {
				display: grid;
				place-items: flex-end;
				margin-bottom: 0;
				max-width: 750px;
				overflow: visible;

				img {
					pointer-events: none;
					width: 100%;

					// max-width: 90%;
					transition-duration: 0.3s;
				}

				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}

			@media screen and (width <= 768px) {
				flex-direction: column-reverse;

				.whitebadge {
					top: 50px;
				}
			}
		}
	}
}

@media screen and (width <= 1050px) {
	.section-template {
		.white-bg {
			.whitesection {
				.whitetext {
					margin-top: 6rem;
				}
			}
		}

		.black-bg {
			.blacksection {
				.text {
					margin-top: 6rem;
				}
			}
		}
	}
}
</style>
