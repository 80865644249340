<template>
	<div
		:class="{ active: modelValue && animate, inactive: !modelValue && animate }"
		class="burger-menu-wrapper"
		@click="emitChange"
	>
		<div class="line1"></div>
		<div class="line2"></div>
		<div class="line3"></div>
	</div>
</template>

<script>
import { ref } from "vue";
export default {
	name: "BurgerMenuOne",
	props: {
		modelValue: {
			required: true,
			type: Boolean,
		},
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		const animate = ref(false);
		const emitChange = function () {
			animate.value = true;
			emit("update:modelValue", !props.modelValue);
		};

		return {
			emitChange,
			animate,
		};
	},
};
</script>

<style lang="scss" scoped>
$spaceing: 6px;
$width: 31px;
$burger-menu-main-color: #fff;
$burger-menu-secondary-color: #fff;

.burger-menu-wrapper {
	max-width: $width;
	width: $width;
	position: relative;
	min-height: 27px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.line1,
	.line2,
	.line3 {
		width: $width;
		height: 3px;
		border-radius: 0;
		background-color: $burger-menu-main-color;
		animation-duration: 0.4s;
	}

	.line2 {
		margin-bottom: $spaceing;
		margin-top: $spaceing;
	}
}

.burger-menu-wrapper.active {
	.line1 {
		animation-name: active1;
		animation-fill-mode: forwards;
	}

	.line2 {
		animation-name: active2;
		animation-fill-mode: forwards;
	}

	.line3 {
		animation-name: active3;
		animation-fill-mode: forwards;
	}
}

@keyframes active1 {
	0% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	50% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	100% {
		transform: rotate(45deg);
		background-color: $burger-menu-secondary-color;
	}
}

@keyframes inactive1 {
	0% {
		transform: rotate(45deg);
		background-color: $burger-menu-secondary-color;
	}

	50% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	100% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}
}

@keyframes active2 {
	0% {
		margin-top: $spaceing;
		margin-bottom: $spaceing;
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	50% {
		margin-top: -3px;
		margin-bottom: -3px;
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	100% {
		margin-top: -3px;
		margin-bottom: -3px;
		transform: rotate(-45deg);
		background-color: $burger-menu-secondary-color;
	}
}

@keyframes inactive2 {
	0% {
		margin-top: -3px;
		margin-bottom: -3px;
		transform: rotate(-45deg);
		background-color: $burger-menu-secondary-color;
	}

	50% {
		margin-top: -3px;
		margin-bottom: -3px;
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	100% {
		margin-top: $spaceing;
		margin-bottom: $spaceing;
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}
}

@keyframes active3 {
	0% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	50% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	100% {
		transform: rotate(-45deg);
		background-color: $burger-menu-secondary-color;
	}
}

@keyframes inactive3 {
	0% {
		transform: rotate(-45deg);
		background-color: $burger-menu-secondary-color;
	}

	50% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}

	100% {
		transform: rotate(0deg);
		background-color: $burger-menu-main-color;
	}
}

.burger-menu-wrapper.inactive {
	.line1 {
		animation-name: inactive1;
		animation-fill-mode: forwards;
	}

	.line2 {
		animation-name: inactive2;
		animation-fill-mode: forwards;
	}

	.line3 {
		animation-name: inactive3;
		animation-fill-mode: forwards;
	}
}
</style>
