<template>
	<div class="home">
		<top-section />
		<info-section />
		<help-section />
		<chart-section />
		<what-people-say />
		<!-- <contact-section /> -->
	</div>
</template>

<script setup>
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
onMounted(() => {
	animateElementsOnScroll(
		".home",
		"slideFromBot 1s 0s forwards",
		".info, .card-wrapper, .animate, .recension-card",
	);
});
</script>
<style lang="scss" scoped>
.home {
	@media screen and (width <768px) {
		overflow-x: hidden;
	}
}
</style>
