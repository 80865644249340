<template>
	<div id="testimonial">
		<div class="what-people-say-inner">
			<h1 class="animate">What people said about us</h1>
			<div class="cards-container">
				<recension-card v-for="card in cards" :key="card.text" :data="card" />
			</div>
		</div>
	</div>
</template>
<script setup>
import cpl from "@/assets/images/cpl.svg";
import fw from "@/assets/images/fairway.svg";
import sp from "@/assets/images/smartpoint.svg";
import mi from "@/assets/images/xiaomi.svg";
const cards = [
	{
		imageUrl: cpl,
		name: "Tom Brady",
		position: "Senior Project Manager at Cpl",
		text: `ENON Solutions offers a <b>powerful suite of developer and software experience</b> that every business should inquire about. If you get stuck, their responsive support team will help you out with any developed solution. I'm using ENON to manage the entire time & attendance and rostering app for our healthcare business in the Irish & UK market.`,
		subtext: `Thanks to ENON Solutions' continued support and development, Cpl Healthcare has grown its healthcare business by 20%.`,
	},
	{
		imageUrl: fw,
		name: "Justin Bennett",
		position: "Fairwey - Owner",
		text: `The ENON Solutions Team was absolutely fantastic to work with. This was my first time working on a project of this scope, and the team at <b>ENON was very patient, attentive, and professional.</b> Strahinja really took the time to understand our business model and our desired outcomes to build a platform that will help us achieve our goals.`,
		subtext: `Highly recommend this team!`,
	},
	{
		imageUrl: sp,
		name: "Milivoj Đorđić",
		position: "General Manager, SmartPoint",
		text: `Our cooperation with the ENON Solutions Team reflects a genuine partnership and a professional approach to every project.`,
		subtext: `Truly one of the few companies we can always recommend.`,
	},
	{
		imageUrl: mi,
		name: "Đorđe Jovanović",
		position: "Marketing Director, Xiaomi Balkan",
		text: `<b>Even in the challenges of short deadlines and demanding tasks</b>,
          the ENON Solutions Team managed to find the right approach and provide support for our plans and campaigns.`,
		subtext: `Every project was completed on time and as agreed.`,
	},
];
</script>
<style scoped lang="scss">
#testimonial {
	background: $section-back;
	color: $app-color;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 5rem 0;

	// background-image: url("@/assets/images/whitelinebg.png");
	background-image: url("@/assets/images/whitebackalt2.png");
	background-size: 100% 100%;

	.what-people-say-inner {
		width: 100%;
		max-width: min($content-max-width, 1500px);
		font-size: 54px;
		text-align: center;

		.animate {
			font-size: 54px;
		}

		.cards-container {
			margin-top: 5rem;
			display: flex;
			gap: 5rem;
			flex-flow: row wrap;
			justify-content: space-around;
		}
	}
}

@media screen and (width <= 768px) {
	#testimonial {
		h1 {
			font-size: 1.8rem;
			margin: 0 1rem 1rem;
		}

		.what-people-say-inner {
			.animate {
				font-size: 32px;
			}

			.cards-container {
				margin-top: 0;
				gap: 2rem;
			}
		}
	}
}
</style>
