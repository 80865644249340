<template>
	<div class="footer-wrapper">
		<turn-stile @token-change="setToken" />
		<inline-svg class="glow-img" :src="glowImg"></inline-svg>
		<div class="footer-upper">
			<div class="left">
				<div class="inner-wrap">
					<img
						class="logo"
						src="@/assets/images/upgraded/enon-logo-white.svg"
						alt=""
					/>

					<span class="text">
						<div class="first">
							<inline-svg :src="pinSvg" alt="" />
						</div>
						<div class="second desktop" @click="onClickAddress">
							<div>Bulevar Mihajla Pupina 13</div>
							<div>Belgrade, Serbia</div>
						</div>
						<span class="second mobile" @click="onClickAddress">
							Bulevar Mihajla Pupina 13
							<br />
							Belgrade, Serbia
						</span>
					</span>
				</div>
			</div>
			<div class="middle">
				<div class="middle-upper">
					<a
						href="mailto:info@enonsolutions.com?subject = Feedback&Contact = Message"
						class="mail"
					>
						<inline-svg :src="mailSvg" alt="Mail" />
						<p>info@enonsolutions.com</p>
					</a>
					<div class="social">
						<a
							href="https://x.com/enon_solutions"
							target="_blank"
							rel="me nofollow"
							class="social-item"
						>
							<inline-svg :src="xSvg" alt="X" />
							<p>X</p>
						</a>
					</div>
				</div>
				<div class="middle-lower">
					<div class="social">
						<a
							href="https://www.facebook.com/ENONSolutions"
							target="_blank"
							rel="me nofollow"
							class="middle-lower-social-item"
						>
							<inline-svg :src="fbSvg" alt="Facebook" />
							<p class="middle-lower-social-item-label">Facebook</p>
						</a>
					</div>
					<div class="social">
						<a
							href="https://www.instagram.com/enon_solutions"
							target="_blank"
							rel="me nofollow"
							class="middle-lower-social-item"
						>
							<inline-svg :src="igSvg" alt="Instagram" />
							<p class="middle-lower-social-item-label">Instagram</p>
						</a>
					</div>
					<div class="social">
						<a
							href="https://www.linkedin.com/company/enon-solutions/"
							target="_blank"
							rel="me nofollow"
							class="middle-lower-social-item"
						>
							<inline-svg :src="liSvg" alt="Linkedin" />
							<p class="middle-lower-social-item-label">Linkedin</p>
						</a>
					</div>
				</div>
			</div>
			<div class="right">
				<span>Contact us anytime!</span>
				<div class="newsletter-wrapper">
					<input
						v-if="showQuestion"
						v-model="question"
						placeholder="Your question.."
						type="text"
					/>
					<input
						v-else
						v-model="email"
						placeholder="Your email.."
						type="email"
					/>
					<div
						:class="{ clicked: clicked }"
						class="send-wrapper"
						@mousedown="clicked = true"
						@mouseup="clicked = false"
						@click="askQuestion"
					>
						<img src="@/assets/icons/send.svg" alt="" />
					</div>
				</div>
				<span class="message">{{ message }}</span>
			</div>
		</div>
		<div class="footer-lower">
			<div class="">© 2024 ENON Solutions. All rights reserved.</div>
			<div class="">
				<!-- <span>
					This site is protected by reCAPTCHA and the Google Privacy Policy and
					Terms of Service apply.</span
				> -->
			</div>
		</div>
		<template v-if="canShowModal">
			<div class="overlay" @click.self="canShowModal = !canShowModal">
				<div class="modal">
					<contact-section></contact-section>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
import api from "@/api";
import { ref } from "vue";
// import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";
import mailSvg from "@/assets/images/footer/mail.svg";
import fbSvg from "@/assets/images/footer/fb.svg";
import igSvg from "@/assets/images/footer/ig.svg";
import liSvg from "@/assets/images/footer/in.svg";
import xSvg from "@/assets/images/footer/x.svg";
import pinSvg from "@/assets/images/footer/pin.svg";
import TurnStile from "../helpers/TurnStile.vue";
import glowImg from "@/assets/images/upgraded/glow.svg";

const showQuestion = ref(true);
const email = ref(null);
const question = ref(null);
const clicked = ref(false);
const message = ref(null);
const canShowModal = ref(false);
const turnstile_token = ref("");
// const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

function testEmail(email) {
	// eslint-disable-next-line no-useless-escape
	return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}
async function askQuestion() {
	if (showQuestion.value && question.value) {
		showQuestion.value = false;
	} else {
		if (!testEmail(email.value)) {
			message.value = "Bad email!";
			setTimeout(() => {
				message.value = null;
			}, 4000);
			return;
		}
		if (question.value) {
			// await recaptchaLoaded();
			// const token = await executeRecaptcha("askQuestion");
			let params = {
				token: turnstile_token.value,
				email: email.value,
				message: question.value,
			};
			try {
				let res = await api.sendmail(params);
				message.value = "Question sent successfuly!";
				setTimeout(() => {
					message.value = null;
				}, 3000);
			} catch (e) {
				message.value = "Error sending message!";
				setTimeout(() => {
					message.value = null;
				}, 3000);
			} finally {
				showQuestion.value = true;
				email.value = null;
				question.value = null;
			}
		}
	}
}

function onClickAddress() {
	canShowModal.value = true;
}

function setToken(value) {
	turnstile_token.value = value;
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	align-items: center;
	justify-content: center;
	background: var(--es-background-color);
	padding-top: 52px;
	overflow: hidden;
	font-size: 15px;

	.glow-img {
		position: absolute;
		margin: auto;
		max-width: 20%;
		filter: blur(50px);
	}

	a {
		text-decoration: none;
		color: #fff;
		font-weight: 500;
	}

	.mobile {
		display: none !important;
	}

	.footer-upper {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		max-width: $content-max-width;
		border-bottom: 1px solid $section-color;

		.left {
			display: grid;
			place-items: center;

			.inner-wrap {
				display: flex;
				flex-direction: row;
				height: 80px;
				max-width: 100%;

				img {
					margin-right: 3rem;
					height: 100%;
					width: auto;
					box-sizing: content-box;
				}

				.text {
					max-width: 330px;
					line-height: 26px;
					color: var(--vt-c-white);
					white-space: nowrap;
					display: flex;
					gap: 10px;
					align-items: center;

					&.desktop {
						display: flex;
						gap: 10px;
						align-self: center;
						font-weight: 500;

						// justify-content: space-between;
					}

					.first {
						display: flex;
						gap: 10px;
						align-items: center;
						padding-top: 2px;
					}

					.second {
						display: flex;
						flex-direction: column;
						cursor: pointer;

						&:hover {
							color: $second-app-color;
						}
					}

					a {
						text-decoration: none;
						color: var(--vt-c-white);
					}
				}
			}
		}

		.middle {
			display: flex;
			flex-direction: column;
			height: 80px;

			// align-self: center;
			gap: 8px;
			top: 13px;

			// justify-content: space-between;

			$icon-color: $second-app-color;

			.middle-upper {
				display: flex;

				.mail {
					display: flex;
					align-items: center;
					margin-right: 1.5rem;
					gap: 10px;

					svg {
						:deep() {
							path {
								stroke: $icon-color;
							}
						}
					}

					p {
						transition: all 200ms;
					}

					&:hover {
						p {
							color: $second-app-color;
						}
					}
				}

				.social {
					.social-item {
						display: flex;
						gap: 10px;

						p {
							transition: all 200ms;
						}

						&:hover {
							p {
								color: $second-app-color;
							}
						}
					}
				}
			}

			.middle-lower {
				display: flex;
				align-items: center;

				.social {
					margin-right: 1.5rem;

					.middle-lower-social-item {
						display: flex;
						gap: 10px;
						svg {
							:deep() {
								path {
									fill: $icon-color;
								}
							}
						}

						.middle-lower-social-item-label {
							transition: all 200ms;
						}

						&:hover {
							p {
								color: $second-app-color;
							}
						}
					}
				}
			}
		}

		.right {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-self: center;

			span {
				font-weight: 600;
				font-size: 1.5rem;
				line-height: 22px;
				margin-bottom: 1rem;
				color: var(--vt-c-white);
			}

			h2 {
				font-weight: 600;
				line-height: 57px;
				color: var(--vt-c-white);
			}

			.subtitle {
				line-height: 29px;
				color: $app-color;
				margin: 30px 0;
			}

			.message {
				height: 22px;
				margin-top: 10px;
				margin-left: 1rem;
				color: var(--vt-c-white);
				font-size: 16px;
			}

			.newsletter-wrapper {
				height: 44px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				border: 1px solid;
				border-color: $section-color;
				box-sizing: border-box;
				border-radius: 11px;
				position: relative;
				width: 100%;

				input {
					flex-grow: 1;
					color: var(--vt-c-white);
					background-color: transparent;
					padding-right: 0;
					min-width: 0;

					&::placeholder {
						color: var(--vt-c-white);
						opacity: 1;
					}
				}

				.send-wrapper {
					height: 100%;
					padding: 0 15px;
					cursor: pointer;
					display: flex;
					align-items: center;
					transition-duration: 0.1s;

					img {
						width: 25px;

						&:hover {
							scale: 1.1;
						}
					}
				}

				.clicked {
					transform: scale(0.8);
				}
			}
		}
	}

	.footer-lower {
		height: 105px;
		padding-bottom: 30px;
		border-top: 1px solid rgba(255, 255, 255, 0.17);
		width: 100%;
		max-width: $content-max-width;
		display: flex;
		flex-direction: row;
		padding-top: 5px;
		justify-content: space-between;
		color: $second-app-color;

		.spacer {
			flex-grow: 1;
		}

		div {
			font-weight: 500;
			line-height: 17px;
			margin-right: 1rem;
		}
	}

	.overlay {
		position: fixed;
		inset: 0;
		background-color: #000000ab;
		z-index: 1000;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal {
		width: 90vw;
		height: 90vh;
		z-index: 1000;
	}
}

@media screen and (width <= 1200px) {
	.footer-wrapper {
		padding-top: 50px;

		.desktop {
			display: none !important;
		}

		.mobile {
			display: flex !important;
		}

		.footer-upper {
			display: flex;
			flex-direction: column;
			align-items: center;

			.left {
				margin: 0;
				max-width: min(90vw, 400px);
				width: 100%;

				.inner-wrap {
					display: flex;
					flex-direction: column;
					height: auto;
					align-items: center;
					width: 100%;

					.text {
						margin-right: auto;
					}

					img {
						margin: 0 0 20px;
						height: 80px;
					}
				}
			}

			.middle {
				margin: 5px auto 1rem;
				top: 0;
				max-width: min(90vw, 400px);
				width: 100%;

				.middle-upper,
				.middle-lower {
					gap: 8px;

					.social {
						margin: 0;
					}
				}
			}

			.right {
				max-width: min(90vw, 400px);
				width: 100%;
			}
		}

		.footer-lower {
			display: flex;
			flex-direction: column;
			text-align: center;
			padding: 1rem 0 0;
			justify-content: flex-start;
			gap: 0.3rem;
		}
	}
}
</style>
