<template>
	<!-- <sponsors-section /> -->
	<div class="marquee-wrap">
		<sponsors-section-marquee />
	</div>
	<div class="distancefix"></div>
	<div class="section-wrap">
		<template v-if="showComponent">
			<div v-if="!isMobile" class="chart-wrap">
				<chart></chart>
				<div v-if="showRightSide" class="right-side">
					<!-- CLIENTS NAME -->
					<transition
						name="fade-from-top"
						mode="out-in"
						appear
						@after-enter="showNK = true"
					>
						<div class="clients-number">
							<img src="@/assets/images/chartClientsNumber2.svg" alt="" />
						</div>
					</transition>

					<!-- NK -->
					<transition
						name="fade-from-top"
						mode="out-in"
						appear
						@after-enter="showStars = true"
					>
						<div v-if="showNK" class="nk">
							<div class="bars">
								<div class="bar" :class="{ appear: showStars }"></div>
								<div class="bar second" :class="{ appear: showStars }"></div>
							</div>
							<img src="@/assets/images/chartNk.svg" alt="" />
						</div>
					</transition>

					<!-- STARS -->
					<transition-group
						v-if="showStars"
						class="stars"
						tag="div"
						name="star-slide"
						appear
						@after-enter="starEnter"
					>
						<img
							v-for="star in startCount"
							:key="star"
							class="star"
							src="@/assets/images/chartStar.svg"
							alt="star.svg"
						/>
					</transition-group>
				</div>
			</div>

			<div v-if="isMobile" class="chart-wrap">
				<video class="video-chart" autoplay muted>
					<source src="@/assets/images/chart-anim-3.mp4" type="video/mp4" />
				</video>
			</div>
			<transition name="fade-from-top" mode="out-in" appear>
				<div class="paragraph">
					<h2>Our business philosophy</h2>
					<p>
						For us, each client and project represents a unique story, solution,
						and experience. With that in mind, we do our best to apply our
						expertise and help our clients use the best of the latest
						technologies and meet their business goals. We are lucky enough that
						the majority of our clients see the value in that and decide to
						continue working with us on additional projects related to software
						development.
					</p>
					<div class="green-bar"></div>
				</div>
			</transition>
		</template>
	</div>
</template>

<script>
import { ref, onUnmounted } from "vue";
export default {
	setup() {
		const showComponent = ref(false);
		const showRightSide = ref(false);
		const showNK = ref(false);
		const showStars = ref(false);
		const startCount = ref(1);
		const isMobile = ref(window.innerWidth < 701);

		function starEnter() {
			if (startCount.value < 5) {
				startCount.value++;
			}
		}

		setTimeout(() => {
			showRightSide.value = true;
		}, 1850);

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					showComponent.value = true;
				}
			});
		});

		setTimeout(() => {
			const el = document.querySelector(".section-wrap");
			if (el) observer.observe(el);
		}, 1000);

		function setIsMobile() {
			isMobile.value = window.innerWidth < 701;
		}

		window.addEventListener("resize", setIsMobile);

		onUnmounted(() => {
			window.removeEventListener("resize", setIsMobile);
		});

		return {
			showComponent,
			showRightSide,
			showNK,
			showStars,
			startCount,
			starEnter,
			isMobile,
		};
	},
};
</script>

<style lang="scss" scoped>
$chart-height: 700px;
$distance-fix-height: 550px;

.marquee-wrap {
	margin-top: 2rem;
	max-width: 100vw;
	overflow: hidden;
}

.distancefix {
	height: $distance-fix-height;
}

.section-wrap {
	background-image: url("@/assets/images/upgraded/project-black-bg-left.png");
	background-size: 100% 100%;
	min-height: 1000px;

	.partners {
		width: 100%;
		height: 300px;
	}

	.chart-wrap {
		margin-top: calc(0px - (#{$distance-fix-height} - 50px));
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: $chart-height;

		.right-side {
			position: absolute;
			left: calc(50% + 55px);
			top: calc(50% - 205px);

			.nk {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-top: 70px;
				margin-right: -20px;

				.bars {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					margin-right: 21px;
					margin-top: 10px;

					.bar {
						background: #1a3443;
						border-radius: 10px;
						height: 11px;
						transition: width 1s ease;
						width: 0;

						&.appear {
							width: 117px;
						}

						&.second {
							margin: 10px 0;

							&.appear {
								width: 150px;
							}
						}
					}
				}
			}

			.stars {
				margin-top: 30px;
				display: flex;
				flex-direction: row-reverse;
				justify-content: flex-start;
				margin-right: -20px;

				.star {
					margin: 0 5px;
				}
			}
		}
	}

	// .video-chart {
	//   width: 80%;
	// }
	.paragraph {
		text-align: center;
		width: 85%;
		max-width: 1047px;
		margin: -60px auto 0;
		color: #fff;
		padding-bottom: 100px;

		h2 {
			font-size: 32px;
			margin-bottom: 15px;

			@media screen and (width > 768px) {
				font-size: 54px;
			}
		}

		p {
			font-size: 15px;
			line-height: 30px;

			@media screen and (width > 768px) {
				line-height: 46px;
				font-size: 18px;
			}
		}

		.green-bar {
			width: 93px;
			height: 9px;
			background: #53aa6c;
			margin: 77px auto 0;
			border-radius: 20px;
		}
	}
}

@media (width <= 1366px) {
	.section-wrap {
		.chart-wrap {
			transform: scale(0.9);
		}
	}
}

@media (width <= 1024px) {
	.section-wrap {
		padding-top: 0;

		.chart-wrap {
			transform: scale(0.8);
		}
	}
}

@media (width <= 768px) {
	.distancefix {
		height: 350px;
	}

	.section-wrap {
		.chart-wrap {
			transform: scale(0.7);
		}
	}
}

@media (width <= 600px) {
	.section-wrap {
		.chart-wrap {
			transform: scale(0.6);
		}
	}
}

@media (width <= 400px) {
	.section-wrap {
		background-image: none;

		.chart-wrap {
			transform: scale(0.5);

			.right-side {
				left: 200px;
			}
		}
	}
}

.fade-from-top-enter-active {
	transition: all 0.5s ease-out;
}

.fade-from-top-leave-active {
	// position: absolute;
	transition: all 0.5s ease-out;
}

.fade-from-top-enter-from {
	opacity: 0;
	transform: translateY(50px);
}

// .fade-from-top-leave-to {
//   opacity: 0;
//   transform: translateY(-30px);
// }
// .fade-from-top-move {
//   transition: all .3s;
// }

.star-slide-enter-active {
	transition: all 0.2s ease-out;
}

.star-slide-leave-active {
	transition: all 0.2s ease-out;
}

.star-slide-enter-from {
	opacity: 0;
	transform: translateX(-30px);
}
</style>
