<script setup lang="ts">
const teamInfo = computed(() => {
	return [
		{
			imageInfo: "7+",
			imageSub: "Frontend Developers",
			text: "Experienced in JavaScript, Vue.js, HTML, & CSS, and building scalable front-end codebases, ensuring your website's seamless performance and impressive user experience.",
		},
		{
			imageInfo: "6+",
			imageSub: "Backend Developers",
			text: "Experienced in Java, JavaScript/Node.js/MARS Engine, Laravel/PHP, and other web tools for backend development, promising robust and reliable system operations to support your digital infrastructure.",
		},
		{
			imageInfo: "3+",
			imageSub: "Mobile Developers",
			text: "Experienced in Cordova, Capacitor, and various frameworks for comprehensive iOS and Android development, delivering fast, responsive, and user-friendly mobile applications.",
		},
		{
			imageInfo: "4+",
			imageSub: "Project Managers",
			text: "Experienced in Agile/Scrum methodologies, risk management, communication with stakeholders, and team coordination using tools like Jira, Asana, and Trello, ensuring timely delivery and efficient execution of your projects.",
		},
		{
			imageInfo: "2+",
			imageSub: "Designers",
			text: "Experienced in UI/UX design, Adobe Creative Suite, Sketch, Figma, and prototyping tools like InVision and Marvel App, crafting captivating and intuitive interfaces to elevate your brand image.",
		},
		{
			imageInfo: "2+",
			imageSub: "DevOps Engineers",
			text: "Experienced in AWS, Jenkins, CI/CD pipelines, Kubernetes, and Terraform for infrastructure management and automated deployments, securing high availability and reliability of your services.",
		},
		{
			imageInfo: "3+",
			imageSub: "Qualified Testers",
			text: "Experienced in manual and automated testing, using tools like Selenium, JUnit, TestNG, Appium, and frameworks like Cucumber, with knowledge in performance and security testing, safeguarding your products against bugs and vulnerabilities.",
		},
		{
			imageInfo: "3+",
			imageSub: "Database Experts",
			text: "Experienced in Microsoft SQL Server, Oracle, PostgreSQL, NoSQL, and other databases, specializing in the design of efficient database structures and query-level optimization that power high-performance applications.",
		},
	];
});
</script>

<template lang="pug">
.team-info
	label Experts in various fields!
	.row-info
		.row(v-for="(row, index) in teamInfo", :key="index")
			.spinner-image
				img.a.a-back(
					:draggable="false",
					:alt="row.imageSub",
					src="@/assets/icons/spinner-back.svg"
				)
				img.a.a1(:draggable="false", src="@/assets/icons/spinner-team.svg")
				img.a.a2(:draggable="false", src="@/assets/icons/spinner-team.svg")
				img.a.a3(:draggable="false", src="@/assets/icons/spinner-team.svg")
				img.b(:draggable="false", src="@/assets/icons/spinner-inner.svg")
				.center-text
					//- .text-up {{ row.imageInfo }}
					.text-down {{ row.imageSub }}
			.desc {{ row.text }}
</template>

<style lang="scss" scoped>
.team-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 2rem;
	gap: 50px;
	max-width: 1500px;

	@media screen and (width >= 768px) {
		gap: 7rem;
		padding: 7rem 4rem;
	}

	label {
		color: #fff;
		text-align: center;
		font-size: 54px;
		font-family: Archivo, sans-serif;
		font-style: normal;
		font-weight: 600;
		line-height: 54px;

		@media screen and (width < 768px) {
			font-size: 32px;
		}
	}

	.row-info {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;

		.row {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			.spinner-image {
				$size: 150px;

				width: $size;
				min-width: $size;
				height: $size;
				user-select: none;
				pointer-events: none;

				img.a {
					position: absolute;
					animation-name: spin;
					animation-duration: 5000ms;
					animation-iteration-count: infinite;
					animation-timing-function: linear;

					// animation-delay: ;
					width: 100%;
					height: 100%;

					&.a1 {
						animation-duration: 5000ms;
					}

					&.a2 {
						animation-duration: 4000ms;
						animation-direction: reverse;
					}

					&.a3 {
						animation-duration: 6000ms;
						animation-direction: reverse;
					}
				}

				img.b {
					$pos: 15px;

					position: absolute;
					left: $pos;
					top: $pos;
					width: 80%;
					height: 80%;
				}

				.center-text {
					position: absolute;
					top: 48%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					text-align: center;
					font-style: normal;
					font-weight: 600;
					line-height: normal;

					.text-up {
						font-size: 25px;
						font-weight: bold;
					}

					.text-down {
						font-size: 15px;
					}
				}

				@media screen and (width >= 768px) {
					$size: 180px;

					width: $size;
					min-width: $size;
					height: $size;

					img.a {
						width: unset;
						height: unset;
					}

					img.b {
						$pos: 15px;

						left: $pos;
						top: $pos;
						width: unset;
						height: unset;
					}

					.center-text {
						.text-up {
							font-size: 50px;
						}

						.text-down {
							font-size: 20px;
						}
					}
				}
			}

			@for $i from 1 through 8 {
				&:nth-child(#{$i}) {
					.spinner-image {
						img {
							animation-delay: 300ms * $i;
						}
					}
				}
			}

			.desc {
				padding: 10px;
				background: linear-gradient(
					270deg,
					#13181f 0%,
					rgba(19, 24, 31, 0) 100%
				);
				border-radius: 25px;
			}
		}

		@media screen and (width >= 768px) {
			gap: 30px 70px;
			grid-template-columns: 1fr 1fr;

			.row {
				flex-direction: row;
				gap: 20px;

				.desc {
					padding: 50px;
					font-size: 18px;
				}
			}
		}
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	@keyframes spin-alt {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}
</style>
