<template>
	<div class="help-outer-wrapper">
		<div class="help-inner-wrapper">
			<h1 class="animate">How can we help you?</h1>
			<div class="cards-container">
				<help-card
					v-for="card in cardsData"
					:key="card.title"
					:data="card"
					:class="[card.classWrap]"
				/>
			</div>
		</div>
	</div>
</template>
<script setup>
import asset1 from "@/assets/images/helpicons/analysis.svg";
import asset2 from "@/assets/images/helpicons/software.svg";
import asset3 from "@/assets/images/helpicons/services.svg";
import asset4 from "@/assets/images/helpicons/web.svg";
import asset5 from "@/assets/images/helpicons/mobile.svg";
import asset6 from "@/assets/images/helpicons/cloud.svg";
import asset7 from "@/assets/images/helpicons/delivery.svg";
import asset8 from "@/assets/images/helpicons/uiux.svg";

const cardsData = [
	{
		imgUrl: asset1,
		classWrap: "busi",
		class: "f",
		title: "Business Analysis",
		text: `Software is just one part of your business. To make the most of your software, you must know which part of your business can be augmented by software - and we can help!`,
	},
	{
		imgUrl: asset2,
		classWrap: "software",
		class: "f",
		title: "Software Development",
		text: `We develop software to fit your needs. Following the design and specification document, we can help bring your software to life.`,
	},
	{
		imgUrl: asset3,
		classWrap: "dist",
		class: "f",
		title: "Distributed Services Development",
		text: `Once you scale, monoliths no longer get the job done. We have the experience that can help you scale and build an array of distributed services that can be leveraged internally or externally by your users.`,
	},
	{
		imgUrl: asset4,
		classWrap: "web",
		class: "s",
		title: "Web Development",
		text: `The web is the most popular platform for launching a product or a service! Your software is only one browser tap away, and we can utilize our experience to ensure your software can be used by many users around the world.`,
	},
	{
		imgUrl: asset5,
		classWrap: "mobi",
		class: "mob",
		title: "Mobile  Development",
		text: `Your solution in the palm of a hand. Whether you are building the next Uber or an international tracking system - sometimes apps are the way to go. Our approach to building apps is proven to save on development costs and time.`,
	},
	{
		imgUrl: asset6,
		classWrap: "cloud",
		class: "s",
		title: "Cloud Services and Infrastructure",
		text: `In order to scale, your business must leverage the cloud and optimize costs. We have experience with AWS and other hosting providers that can help you create the perfect infrastructure.`,
	},
	{
		imgUrl: asset7,
		classWrap: "cont",
		class: "f",
		title: "Continous Delivery",
		text: `Developing software in a continuous loop of development, deployment, and feedback is much more productive for the business as you get to iterate on ideas. We develop software with continuous delivery in mind.`,
		isEnchanced: true,
	},
	{
		imgUrl: asset8,
		classWrap: "ui",
		class: "s",
		title: "UX & UI and Product Design",
		text: `Good software works well; great software also looks good. We have experience designing products with the utmost usability and the easiest adoption in mind.`,
	},
];
</script>
<style scoped lang="scss">
.help-outer-wrapper {
	background-image: url("@/assets/images/whitebackalt.png");
	background-size: 100% 100%;
	background-color: $section-back;
	color: $app-color;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 5rem 0;

	.help-inner-wrapper {
		width: 100%;
		max-width: $content-max-width;
		font-size: 18px;
		text-align: center;
		overflow: hidden;
		color: $section-color;

		.animate {
			font-size: 54px;
			color: $app-color;
		}

		.cards-container {
			margin-top: 7rem;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-around;
		}
	}
}

@media (width <= 768px) {
	.help-outer-wrapper {
		.help-inner-wrapper {
			.animate {
				font-size: 32px;
			}

			.cards-container {
				margin-top: 0;
			}
		}

		.help-inner-wrapper h1 {
			font-size: 2.5rem;
		}
	}
}
</style>
