<template>
	<div class="page-wrapper">
		<h1>Something went wrong.</h1>
		<p>Error 404 <span> Page Not Found!</span></p>
		<img class="image" src="@/assets/images/404.png" alt="" />
		<div class="btn-wrapper">
			<router-link :to="{ name: 'Home' }">
				<div class="btn">
					<img src="@/assets/icons/back.png" alt="" />
					Home page
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "PageNotFound",
};
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	flex-direction: column;
	padding: 0 20px;
	text-align: center;

	h1 {
		margin-top: 164px;
		color: #142c3a;
	}

	p {
		font-family: Archivo, sans-serif;
		font-weight: 500;
		size: 21px;
		line-height: 40px;
		color: #142c3a;

		span {
			color: #53aa6c;
			font-weight: 700;
		}
	}

	.btn img {
		margin-right: 17px;
	}

	.image {
		margin-top: 88px;
		margin-bottom: 127px;
		max-width: 100%;
	}
}
</style>
