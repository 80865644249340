<script lang="ts" setup>
import { useDefaultStore } from "@/store";
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import glowImg from "@/assets/images/upgraded/glow.svg";

const route = useRoute();
const store = useDefaultStore();
const post = ref<any>(null);
const postLoader = ref(true);

async function getIdFromPath() {
	const params = route.params as { slug: string | string[] };

	const slug = ref("");

	if (Array.isArray(params.slug)) {
		slug.value = params.slug[0];
	} else {
		slug.value = params.slug;
	}
	await getPosts(slug.value);
}

async function getPosts(pst_slug: string) {
	const params = {
		pst_slug,
	};

	try {
		const res = await axios.get(`${store.blogRootApi}posts/posts-get`, {
			params,
		});
		if (res.data.data && res.data.data.length > 0) {
			post.value = res.data.data[0];

			if (post.value.tags) {
				post.value.tags = JSON.parse(post.value.tags);
			}
		}
		// console.log(res.data.data[0]);
	} catch (err: any) {
		// console.log(err.response.data.message);
		console.warn(err.message);
	} finally {
		if (!post.value || !post.value.pst_id) postLoader.value = false;
	}
}

onMounted(async () => {
	await getIdFromPath();
	animateElementsOnScroll(
		".post-page-wrapper",
		"slideFromBot 1s 0s forwards",
		".post-page-title-wrapper, .post-page-poster-wrapper, .post-page-date-wrapper, .post-page-content-wrapper, .post-page-tags-wrapper",
	);
});
</script>

<template>
	<div class="post-page-wrapper">
		<div v-if="postLoader" class="post-page-inner-wrapper">
			<template v-if="!post || !post.pst_id">
				<div class="post-page-title-wrapper post-page-title-wrapper-skeleton">
					<h1></h1>
				</div>
				<div
					class="post-page-date-wrapper post-page-date-wrapper-skeleton"
				></div>
				<div class="post-page-content-wrapper">
					<div
						class="post-page-content-image post-page-content-image-skeleton"
					></div>
					<p
						v-for="(_, index) in 4"
						:key="index"
						class="post-page-content-text post-page-content-text-skeleton"
					></p>
				</div>
				<div class="post-page-tags-wrapper">
					<div
						v-for="(_, index) in 4"
						:key="index"
						class="post-page-tag-wrapper post-page-tag-wrapper-skeleton"
					></div>
				</div>
			</template>
			<template v-else>
				<div class="post-page-title-wrapper">
					<h1>{{ post.pst_title }}</h1>
				</div>
				<div class="post-page-date-wrapper">
					{{ store.utcToLocale(post ? post.pst_utc_datetime : null, true) }}
				</div>
				<div class="post-page-content-wrapper">
					<img
						v-if="post.poster"
						:src="post.poster"
						:alt="post.poster_name ?? post.pst_title"
						class="post-page-content-image"
					/>
					<p
						class="post-page-content-text"
						v-html="post ? post.pst_content : null"
					></p>
				</div>
				<div class="post-page-tags-wrapper">
					<template v-if="post.tags">
						<router-link
							v-for="tag in post.tags"
							:key="tag.tag_id"
							class="post-page-tag-wrapper"
							:to="{ name: 'Blog', query: { tag: tag.tag_label } }"
						>
							{{ tag.tag_label }}
						</router-link>
					</template>
				</div>
			</template>
			<inline-svg class="glow-img" :src="glowImg"></inline-svg>
		</div>
		<div v-else class="post-page-inner-wrapper-nf">
			<inline-svg class="glow-img" :src="glowImg"></inline-svg>
			<p class="post-page-not-found">Post Not Found!</p>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.post-page-wrapper {
	.post-page-inner-wrapper,
	.post-page-inner-wrapper-nf {
		padding: ($top-page-nav-height + 40px) 0 20px;
		position: relative;
		min-height: 100vh;

		.post-page-title-wrapper {
			width: 100%;
			max-width: 1500px;
			margin: 0 auto;
			margin-bottom: 50px;
			padding: 0 20px;

			h1 {
				font-style: normal;
				font-weight: 600;
				margin-bottom: 48px;
				text-align: center;
				font-size: 32px;
				line-height: 40px;
			}

			&.post-page-title-wrapper-skeleton {
				h1 {
					@include skeleton;

					height: 40px;
					margin: 0 10%;
				}
			}
		}

		.post-page-content-wrapper {
			width: 100%;
			max-width: 1500px;
			margin: 0 auto;
			margin-bottom: 1.2rem;

			.post-page-content-text {
				font-size: 15px;
				line-height: 1.8;
				font-weight: 400;
				padding: 0 20px;

				&.post-page-content-text-skeleton {
					@include skeleton;

					height: 129px;
					margin-bottom: 8px;
				}
			}

			.post-page-content-image {
				width: 100%;
				max-width: 1500px;
				margin: 0 auto;
				margin-bottom: 25px;
				aspect-ratio: 100 / 55.55;
				object-fit: cover;
			}
		}

		.post-page-date-wrapper {
			font-size: 12px;
			margin: 0 auto 25px;
			width: 100%;
			max-width: 1500px;
			padding: 0 20px;
			color: $second-app-color;

			&.post-page-date-wrapper-skeleton {
				@include skeleton;

				height: 19.2px;
				width: 180px;
				margin: 0 0 25px 20px;
			}
		}

		.post-page-tags-wrapper {
			margin: 0 auto;
			width: 100%;
			max-width: 1500px;
			padding: 12px 20px 0;
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			clear: left;

			.post-page-tag-wrapper {
				display: block;
				font-size: 12px;
				border-radius: 12px;
				color: $app-color;
				background-color: rgba($app-color-light, 0.1);
				font-weight: 700;
				padding: 0.35rem 0.6rem;
				border: 2px solid transparent;
				transition: border 0.1s ease;

				&.post-page-tag-wrapper-skeleton {
					@include skeleton;

					height: 44.4px;
					width: 120px;
				}

				&:hover {
					cursor: pointer;
					border: 2px solid $app-color;
				}
			}
		}

		.post-page-not-found {
			width: 100%;
			max-width: 1500px;
			margin: 0 auto;
			padding: 0 20px;
			font-size: 15px;
			line-height: 1.8;
			font-weight: 400;
			margin-bottom: 1.2rem;
			text-align: center;
		}

		.glow-img {
			position: absolute;
			margin: auto;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			z-index: -1;
		}
	}
}

@media screen and (min-width: 768px) {
	.post-page-wrapper {
		.post-page-inner-wrapper,
		.post-page-inner-wrapper-nf {
			padding: ($top-page-nav-height + 200px) 0 5rem;
			max-width: 1500px;
			margin: 0 auto;

			.post-page-title-wrapper {
				margin-bottom: 100px;
				padding: 0 20px;

				h1 {
					font-size: 54px;
					line-height: 48px;
				}

				&.post-page-title-wrapper-skeleton {
					h1 {
						height: 48px;
					}
				}
			}

			.post-page-content-wrapper {
				padding: 0 20px;
				.post-page-content-text {
					padding: 0;
					font-size: 18px;
				}

				.post-page-content-image {
					margin: 8px 20px 8px 0;
					width: 50%;
					float: left;
				}
			}

			.post-page-tags-wrapper {
				.post-page-tag-wrapper {
					font-size: 16px;
				}
			}

			.post-page-date-wrapper {
				font-size: 16px;

				&.post-page-date-wrapper-skeleton {
					height: 25.6px;
				}
			}

			.post-page-not-found {
				font-size: 18px;
			}
		}
	}
}

@media screen and (min-width: 1540px) {
	.post-page-wrapper {
		.post-page-inner-wrapper,
		.post-page-inner-wrapper-nf {
			.post-page-date-wrapper {
				padding: 0;

				&.post-page-date-wrapper-skeleton {
					margin-left: 0;
				}
			}

			.post-page-tags-wrapper {
				padding: 25px 0 0;
			}

			.post-page-content-wrapper {
				padding: 0;

				.post-page-content-image {
					width: 40%;
				}
			}

			.post-page-not-found {
				padding: 0;
			}
		}
	}
}
</style>
