export function debounce(func, waitTimer, immediate) {
	// @ts-ignore
	let timeout = null;
	return function () {
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		// @ts-ignore
		const callNow = immediate && !timeout;
		// @ts-ignore
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}
export function howMuchScrolledFromTop() {
	const scrollTop =
		window.pageYOffset !== undefined
			? window.pageYOffset
			: (document.documentElement || document.body.parentNode || document.body)
					.scrollTop;

	return scrollTop;
}
