<template>
	<div id="map">
		<div
			id="mapContainer"
			ref="hereMap"
			style="height: 100%; width: 100%"
		></div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
	name: "HereMap",
	props: {
		center: Object,
	},
	setup(props) {
		const API_KEY = ref("q6V7NZKzA05vpt-gp4RsX_ZtKY3X61_IeNEkOp-a-Hc");
		const platform = ref(null);
		const hereMap = ref(null);

		onMounted(() => {
			platform.value = new window.H.service.Platform({
				apikey: API_KEY.value,
			});
			initializeHereMap();
		});

		function initializeHereMap() {
			const mapContainer = hereMap.value;
			const H = window.H;
			// Obtain the default map types from the platform object
			const maptypes = platform.value.createDefaultLayers();

			// Instantiate (and display) a map object:
			const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
				zoom: 10,
				center: props.center,
			});

			addEventListener("resize", () => map.getViewPort().resize());

			// GET COORDS ON CLICK IF NEEDED
			// map.addEventListener('tap', function (evt) {
			//   const coords = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
			//   console.log(coords);
			// });

			// add behavior control
			const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
			behavior.disable(H.mapevents.Behavior.WHEELZOOM);

			// add UI
			H.ui.UI.createDefault(map, maptypes);

			setPin(map);
		}
		function setPin(map) {
			const svgMarkup = `<svg width="38" height="60" viewBox="0 0 38 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="19.5" cy="48.5" rx="18.5" ry="11.5" fill="#43b463" fill-opacity="0.27"/>
        <path d="M19 49C15.5717 46.0677 12.3939 42.8531 9.49998 39.3897C5.15713 34.1886 2.38556e-06 26.4426 2.38556e-06 19.0614C-0.00188105 15.2917 1.11152 11.6063 3.1993 8.47136C5.28709 5.33647 8.2554 2.89307 11.7286 1.45041C15.2018 0.00774789 19.0237 -0.369333 22.7107 0.366889C26.3977 1.10311 29.784 2.91954 32.4411 5.5863C34.2101 7.35229 35.6123 9.45256 36.5665 11.7656C37.5206 14.0786 38.0078 16.5583 37.9999 19.0614C37.9999 26.4426 32.8428 34.1886 28.4999 39.3897C25.606 42.8531 22.4282 46.0677 19 49ZM19 10.8963C16.8403 10.8963 14.7692 11.7566 13.2421 13.2878C11.715 14.8191 10.8571 16.8959 10.8571 19.0614C10.8571 21.2269 11.715 23.3037 13.2421 24.835C14.7692 26.3662 16.8403 27.2265 19 27.2265C21.1596 27.2265 23.2307 26.3662 24.7578 24.835C26.2849 23.3037 27.1428 21.2269 27.1428 19.0614C27.1428 16.8959 26.2849 14.8191 24.7578 13.2878C23.2307 11.7566 21.1596 10.8963 19 10.8963Z" fill="#43b463"/>
        <ellipse cx="19.5" cy="48.5" rx="13.5" ry="7.5" fill="#43b463" fill-opacity="0.27"/>
        <ellipse cx="19.5" cy="48.5" rx="10.5" ry="5.5" fill="#43b463" fill-opacity="0.27"/>
        </svg>`;
			const H = window.H;
			const bearsIcon = new H.map.Icon(svgMarkup);
			const bearsMarker = new H.map.Marker(props.center, { icon: bearsIcon });

			map.addObject(bearsMarker);

			map.getViewModel().setLookAtData(
				{
					position: props.center,
					zoom: 16.5,
				},
				// true of false for zoom animation
				false,
			);
		}

		return {
			hereMap,
		};
	},
};
</script>

<style scoped>
#map {
	max-width: 100%;
	width: 100%;
	height: 100%;
	min-width: 360px;
	text-align: center;
	margin: 0 auto 5%;
	background-color: #ccc;
}
</style>
