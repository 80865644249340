<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
onMounted(() => {
	animateElementsOnScroll(
		".techno",
		"slideFromBot 1s 0s forwards",
		".tech-section",
	);
});
</script>

<template lang="pug">
.techno
	tech-section1
	tech-section2
	tech-section3
</template>

<style lang="scss" scoped>
.techno {
	display: flex;
	flex-direction: column;
}
</style>
