<template>
	<Teleport v-if="show" to="body">
		<img
			class="scroll-top"
			src="@/assets/icons/scrollTop.svg"
			alt="scroll top image"
			@click="scrollTop"
		/>
	</Teleport>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
export default {
	name: "ScrollTop",
	setup() {
		const show = ref(false);

		function scrollTop() {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}

		function onScroll(e) {
			if (window.scrollY > 1000) {
				show.value = true;
			} else {
				show.value = false;
			}
		}

		onMounted(() => {
			window.addEventListener("scroll", onScroll);
		});

		onUnmounted(() => window.removeEventListener("scroll", onScroll));

		onScroll();

		return {
			scrollTop,
			show,
		};
	},
};
</script>

<style lang="scss" scoped>
.scroll-top {
	position: fixed;
	z-index: 2;
	right: 20px;
	bottom: 20px;
	cursor: pointer;
	animation-name: bounce-7;
	animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
	animation-duration: 2s;
	animation-iteration-count: infinite;
	transform-origin: bottom;
}

@keyframes bounce-7 {
	0% {
		transform: scale(1, 1) translateY(0);
	}

	10% {
		transform: scale(1.1, 0.9) translateY(0);
	}

	30% {
		transform: scale(0.9, 1.1) translateY(-30px);
	}

	40% {
		transform: scale(1.05, 0.95) translateY(0);
	}

	47% {
		transform: scale(1, 1) translateY(-7px);
	}

	64% {
		transform: scale(1, 1) translateY(0);
	}

	100% {
		transform: scale(1, 1) translateY(0);
	}
}
</style>
