<template>
	<div class="bottom-section-outer">
		<div class="bottom-section-inner">
			<div class="right-part">
				<h1 class="header">
					And many
					<div class="underlined">
						<span> more!</span>
						<img src="@/assets/images/underline2.svg" alt="" />
						<div class="image-overlay"></div>
					</div>
					<br />
					<h5>
						Scalable solutions in a wide range of industries including
						healthcare, fintech, insurance, service industry, and more.
					</h5>
				</h1>
			</div>
			<div class="left-part">
				<img src="@/assets/images/upgraded/bottomsection.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script setup></script>
<style scoped lang="scss">
.bottom-section-outer {
	$back: url("@/assets/images/whitebackalt3.png") $section-back;

	width: 100%;
	display: grid;
	place-content: center;

	// background-color: var(--es-background-color-dirty-white);

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;

		// top: -50%;
		// left: -50%;
		z-index: -1;
		background: $back;
	}

	.bottom-section-inner {
		padding: 4rem 20px 0;
		overflow: hidden;

		// max-width: 1500px;
		width: 100%;
		display: flex;
		align-items: center;
		flex-flow: column;
		justify-content: space-around;

		.left-part {
			min-width: 400px;

			img {
				transition-duration: 0.3s;
				width: 100%;
				height: 100%;
			}

			&:hover {
				img {
					transform: scale(1.05);
				}
			}
		}

		.right-part {
			margin-bottom: 6rem;
			gap: 1rem;
			width: 40%;
			min-width: min(600px, 90vw);
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.header {
				font-weight: 600;
				font-size: 32px;
				color: var(--es-text-color-black);
				white-space: nowrap;
				animation: appear1 1s 0s forwards;
				top: 0;
				opacity: 0;
				width: 100%;
				margin-bottom: 30px;
				text-align: center;

				.underlined {
					font-weight: 600;
					position: relative;
					white-space: nowrap;
					display: inline-block;
					max-height: 100%;

					span {
						z-index: 1;
					}

					img {
						position: absolute;
						width: 110%;
						top: 40px;
						left: 0;
						height: auto;
						z-index: -1;
					}

					.image-overlay {
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 0;
						z-index: 0;
						height: 35px;
						background: var(--es-background-color-dirty-white);
						animation: appear3 0.5s 1s forwards;
						margin-left: 0;
					}
				}
			}
		}

		h5 {
			// color: $app-color !important;
			color: $section-color !important;
			font-size: 18px;
			font-weight: 400;
			max-width: 600px;
			margin-right: auto;
			text-align: center;
		}
	}
}

// @media screen and (width >= 768px) {
// 	.bottom-section-outer {
// 		height: var(--project-section-min-height);
// 	}
// }
</style>
