<script setup lang="ts">
import { ref, watch } from "vue";
import VueTurnstile from "vue-turnstile";

type TTurnStile = {
	siteKey?: string; // Generated from the dashboard
	resetInterval?: number;
	size?: "normal" | "compact";
	theme?: "light" | "dark" | "auto";
	language?: string;
	action?: string;
	appearance?: "always" | "execute" | "interaction-only";
	renderOnMount?: boolean;
};

const props = withDefaults(defineProps<TTurnStile>(), {
	siteKey: import.meta.env.VITE_APP_TURNSTILE_KEY,
	resetInterval: 295_000,
	theme: "light",
	size: "normal",
	language: "auto",
	action: "",
	appearance: "always",
	renderOnMount: true,
});
const emit = defineEmits<{
	"token-change": [value: typeof localToken.value];
}>();

// Token to be sent to backend for verification
const localToken = ref("");

// Emit token to parent for DB
watch(localToken, (token) => {
	if (token) emit("token-change", token);
});
</script>

<template>
	<div class="turnstile-wrapper">
		<vue-turnstile
			v-bind="props"
			v-model="localToken"
			:site-key="siteKey as string"
		></vue-turnstile>
	</div>
</template>

<style lang="scss" scoped></style>
