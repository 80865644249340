import axios from "axios";

// Handles DEV and PROD
const baseURL = import.meta.env.VITE_APP_ROOT_API;
const instance = axios.create({
	baseURL,
});

instance.interceptors.request.use((config) => {
	handleRequestInterceptor(config);

	return config;
});

function handleRequestInterceptor(config) {
	try {
		const sid = sessionStorage.sid;
		if (config.params) {
			config.params.sid = sid;
		} else {
			config.params = {
				sid,
			};
		}
	} catch (err) {
		console.log("eRRor", err);
	}
}

export default instance;
