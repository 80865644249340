<template>
	<div class="card-wrapper">
		<div class="card">
			<div class="front-side">
				<div class="imgwrap">
					<inline-svg :class="[data.class]" :src="data.imgUrl" alt="" />
				</div>
				<h3>{{ data.title }}</h3>
			</div>
			<div class="back-side">
				<div class="wrapimg">
					<inline-svg :class="[data.class]" :src="data.imgUrl" alt="" />
				</div>
				<h3>{{ data.title }}</h3>
				<p>{{ data.text }}</p>
			</div>
		</div>
	</div>
</template>
<script setup>
const props = defineProps({
	data: {
		imgUrl: String,
		title: String,
		text: String,
	},
});
let isHovered = ref(false);
</script>
<style scoped lang="scss">
$card-height: 380px;

.card-wrapper {
	width: 100%;
	max-width: 350px;
	margin-bottom: 1rem;
	background: rgb(245 247 252 / 7%);

	.card {
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: all 0.5s ease;
		backface-visibility: hidden;
		transform: rotateY(180deg);

		@media screen and (width >= 768px) {
			position: absolute;

			.back-side {
				position: absolute;
			}
		}

		.front-side {
			position: absolute;
			width: 100%;
			height: 100%;
			padding: 3rem;
			backface-visibility: hidden;

			.imgwrap {
				margin-bottom: 2rem;
				height: 50px;

				:deep() {
					svg {
						&.f {
							path {
								fill: $section-color;
							}
						}

						&.s {
							path {
								stroke: $section-color;
							}
						}

						&.mob {
							rect:nth-child(1) {
								stroke: $section-color;
							}

							rect:nth-child(2) {
								fill: $section-color;
							}

							rect:nth-child(3) {
								fill: $section-color;
							}

							circle:nth-child(4) {
								fill: $section-color;
							}
						}
					}
				}
			}

			h3 {
				// color: $section-color;
				font-size: 24px;
				line-height: 28px;
			}
		}

		.back-side {
			backface-visibility: hidden;
			transform: rotateY(180deg);
			height: 100%;
			width: 100%;
			padding: 1.5rem;

			@media screen and (width >= 768px) {
				border-top: 6px solid $second-app-color-alt;
				box-shadow: 0 5px 13px 0 rgb(0 0 0 / 10%);
				background-color: $section-back-alt;
			}

			.wrapimg {
				margin-top: 0.5rem;

				:deep() {
					svg {
						&.f {
							path {
								fill: $second-app-color-alt;
							}
						}

						&.s {
							path {
								stroke: $second-app-color-alt;
							}
						}

						&.mob {
							rect:nth-child(1) {
								stroke: $second-app-color-alt;
							}

							rect:nth-child(2) {
								fill: $second-app-color-alt;
							}

							rect:nth-child(3) {
								fill: $second-app-color-alt;
							}

							circle:nth-child(4) {
								fill: $second-app-color-alt;
							}
						}
					}
				}
			}

			h3 {
				margin-top: 1rem;
				line-height: 28px;
				font-size: 24px;
				color: $second-app-color-alt;
			}

			p {
				margin-top: 1.2rem;
				font-size: 17px;
				color: var(--es-background-color);
			}
		}
	}

	&.busi {
		.wrapimg {
			margin-top: 1.5rem !important;
		}
	}

	&.software {
		.wrapimg {
			margin-top: 1.5rem !important;
		}
	}

	&.cont {
		.wrapimg {
			margin-top: 1.5rem !important;
		}
	}

	@media screen and (width >= 768px) {
		height: calc($card-height - 50px);
		top: -40px;

		.card {
			transform: rotateY(0deg);
			height: $card-height;
		}

		&:hover {
			.card {
				transform: rotateY(180deg);
			}
		}
	}
}

// @media (max-width: 500px) {
// }
@media (width <= 700px) {
	.card-wrapper {
		// border: 1px solid black;
		max-width: 90%;

		.card {
			.front-side {
				align-items: center;
				padding-top: 0;
				padding-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.back-side {
				.wrapimg {
					margin-top: 0;
				}

				h3 {
					margin-top: 0;
					margin-bottom: 20px;
					line-height: 1;
					font-size: 20px;
				}

				p {
					margin-top: 0;
					font-size: 15px;
					color: var(--es-background-color);
				}
			}
		}
	}
}
</style>
