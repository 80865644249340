<template>
	<div class="contact-wrap">
		<div class="content">
			<div class="title">
				<img class="blur-title" src="@/assets/images/main/mapglow.svg" />
				<h1>You can find us here!</h1>
			</div>
			<div class="map-wrapper">
				<HereMap :center="center" />
			</div>
			<div class="map-blur"></div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
export default {
	setup() {
		const center = ref({
			lat: 44.81464060754065,
			lng: 20.431643892007866,
		});

		return {
			center,
		};
	},
};
</script>

<style lang="scss" scoped>
.contact-wrap {
	background: white;
	height: 100%;

	.content {
		margin: 0 auto;
		width: 100%;
		height: 100%;

		.title {
			.blur-title {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 150px;
			}
		}

		// max-width: 1920px;
		h1 {
			position: absolute;
			top: 20px;
			color: $app-color;
			text-align: center;
			padding: 2rem 1rem 1rem;
			line-height: 48px;
			width: 100%;
			z-index: 1;
			font-weight: bold;
			font-size: 32px;
			margin: 0 0 3rem;
		}

		.map-wrapper {
			height: 100%;
			overflow: hidden;
			scrollbar-width: none; /* Firefox */
			// margin-bottom: 30px;
		}

		.map-blur {
			position: absolute;
			top: 0;
			width: 100%;
			height: 50px;
			background: linear-gradient(
				180deg,
				$section-back 0%,
				rgb(245 247 252 / 0) 100%
			);
		}
	}

	@media screen and (width >= 768px) {
		.content {
			font-size: 2rem;

			h1 {
				font-size: 54px;
				margin: 0 0 75px;
				line-height: 60px;
			}
		}
	}
}
</style>
