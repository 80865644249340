<template>
	<div class="team-wrapper-outer">
		<div class="team-wrapper-inner">
			<h2>ENON Solutions Team</h2>
			<!-- <h3>Meet the memebrs of our team. We are here to help you!</h3> -->
			<chiefs-section />
		</div>
		<team-info></team-info>
	</div>
</template>

<script setup></script>

<style lang="scss" scoped>
.team-wrapper-outer {
	display: grid;
	place-items: center;
	background: url("@/assets/images/upgraded/project-black-bg.png") no-repeat;
	background-size: 100%;
	padding-top: $top-page-nav-height;

	.team-wrapper-inner {
		max-width: $content-max-width;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		animation: appear1 1s 0s forwards;

		h2 {
			margin-top: 185px;
			font-size: 54px;
		}

		h3 {
			font-weight: 200;
			color: $app-color;
		}
	}

	@media screen and (width < 768px) {
		.team-wrapper-inner {
			h2 {
				margin-top: 3rem;
				font-size: 32px;
			}
		}
	}
}
</style>
