<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="fifth-step-wrapper">
		<h2>
			Give us details on what you need, and our representative will contact you
			as soon as possible
		</h2>
		<div class="fifth-step-form-wrapper">
			<!-- <input v-model="store.quoteEmail" type="text" placeholder="Email*" /> -->
			<input v-model="store.quoteName" type="text" placeholder="Full Name" />
			<textarea
				v-model="store.quoteComment"
				:placeholder="`Gve us some details on what you need: 
• a website
• assistance with marketing or sales
• whatever you need to scale your business
				
Feel free to describe the service that you need, and our representative will contact you.`"
			></textarea>
			<!-- <input
				v-model="store.quoteLastName"
				type="text"
				placeholder="Last Name"
			/>
			<input v-model="store.quotePhone" type="text" placeholder="Phone" /> -->
			<h3 v-if="showContactOptions && selectedContactOption === 'none'">
				How would you like to be contacted?
			</h3>
			<div
				v-if="showContactOptions && selectedContactOption === 'none'"
				class="contact-options-wrapper"
			>
				<div class="item-wrapper" @click="selectedContactOption = 'email'">
					<div class="imgwrap">
						<inline-svg :src="email" alt="icon" />
					</div>
					<h3>Email</h3>
					<!-- <div class="tooltip" :class="getTooltipClass(index)">
						{{ item.text }}
					</div> -->
					<!-- <div class="checkmark">
						<inline-svg class="checkmark-img" :src="checkmark" alt="icon" />
					</div> -->
				</div>
				<div class="item-wrapper" @click="selectedContactOption = 'phone'">
					<div class="imgwrap">
						<inline-svg :src="phone" alt="icon" />
					</div>
					<h3>Phone</h3>
					<!-- <div class="tooltip" :class="getTooltipClass(index)">
						{{ item.text }}
					</div> -->
					<!-- <div class="checkmark">
						<inline-svg class="checkmark-img" :src="checkmark" alt="icon" />
					</div> -->
				</div>
			</div>
			<input
				v-if="selectedContactOption === 'email'"
				v-model="store.quoteEmail"
				type="text"
				placeholder="Email"
			/>
			<input
				v-if="selectedContactOption === 'phone'"
				v-model="store.quotePhone"
				type="text"
				placeholder="Phone"
			/>
			<p
				v-if="
					selectedContactOption === 'email' || selectedContactOption === 'phone'
				"
				class="changed-my-mind"
				@click="changeMethod()"
			>
				Choose another contact method
			</p>
		</div>
		<!-- <div class="privacy-policy">
			By submitting, you are agreeing to our privacy policy, terms of use, and
			code of conduct.
		</div> -->
		<div class="btn-wrapper">
			<!-- <router-link class="btn" :to="{ name: 'QuoteDesign' }">Back</router-link> -->
			<div
				v-if="
					selectedContactOption === 'email' || selectedContactOption === 'phone'
				"
				class="btn btn-next"
				@click.stop="handleSubmit"
			>
				Submit
			</div>
			<div v-if="errorMsgBool" class="error-msg">{{ errorMessage }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import { useDefaultStore } from "@/store";
import email from "@/assets/icons/quote/quote-contact.svg";
import phone from "@/assets/icons/quote/quote-phone.svg";

const store = useDefaultStore();
const router = useRouter();
const errorMessage = ref("");
const errorMsgBool = ref(false);
const errorMsgTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
// const countryCode = ref<string | null>(null);
// const city = ref<string | null>(null);
const showContactOptions = ref(true);
const selectedContactOption = ref<"none" | "email" | "phone">("none");

interface IQuoteParams {
	name: string | null;
	last_name: string;
	email?: string | null;
	phone?: string | null;
	requested_date: string;
	design: string;
	ftr_ids: string;
	comment: string;
	ip: string | null;
	city: string | null;
	country_code: string | null;
	referrer: string | null;
}

async function handleSubmit() {
	await store.getIp();

	const params: IQuoteParams = {
		name: store.quoteName,
		last_name: store.quoteLastName,
		// email: store.quoteEmail,
		// phone: store.quotePhone,
		requested_date: store.quoteDatePickerVal,
		design: store.quoteSelectedDesign,
		ftr_ids: JSON.stringify(store.quoteFeatures),
		comment: store.quoteComment,
		ip: store.userIP,
		city: store.userCity,
		country_code: store.userCountryCode,
		referrer: store.userReferrer,
	};

	// if (!store.quoteName || !store.quoteName.trim()) {
	// 	setErrorTimeout("Name is required.");
	// 	return;
	// }
	// if (!store.quoteLastName || !store.quoteLastName.trim()) {
	// 	setErrorTimeout("Last Name is required.");
	// 	return;
	// }

	if (selectedContactOption.value === "none") {
		setErrorTimeout("You need to provide contact information.");
		return;
	} else if (selectedContactOption.value === "email") {
		if (!store.quoteEmail || !store.quoteEmail.trim()) {
			setErrorTimeout("Email is required.");
			return;
		}
		if (!store.emailRegex.test(store.quoteEmail.trim())) {
			setErrorTimeout("Invalid email.");
			return;
		}
		params.email = store.quoteEmail.trim();
	} else if (selectedContactOption.value === "phone") {
		if (!store.quotePhone || !store.quotePhone.trim()) {
			setErrorTimeout("Phone number is required.");
			return;
		}
		params.phone = store.quotePhone.trim();
	}

	try {
		const res = await axios.post(
			`${store.leadsRootApi}leads/leads-add`,
			params,
		);
		console.warn(res.data.message);
		await router.push({ name: "QuoteDone" });
		store.resetForm();
	} catch (e: any) {
		setErrorTimeout("Something went wrong.");
		console.error(e);
	}
}

function changeMethod() {
	selectedContactOption.value = "none";
	errorMsgBool.value = false;
	errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
	store.quotePhone = null;
	store.quoteEmail = null;
}

// async function getIp() {
// 	try {
// 		const res = await axios.get(`${store.leadsRootApi}users/get-ip`);
// 		store.userIP = res.data.ip;
// 		if (store.userIP) getLoaction(store.userIP);
// 	} catch (e: any) {
// 		console.error(e);
// 	}
// }

// async function getLoaction(ip: string) {
// 	const accessToken = "94c8294a2a11db";
// 	try {
// 		const response = await axios.get(
// 			`https://ipinfo.io/${ip}?token=${accessToken}`,
// 		);
// 		store.userCity = response.data.city;
// 		store.userCountryCode = response.data.country;
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

function setErrorTimeout(msg: string) {
	errorMessage.value = msg;
	errorMsgBool.value = true;
	errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
	errorMsgTimeout.value = setTimeout(() => {
		errorMsgBool.value = false;
	}, 5000);
}

onMounted(async () => {
	// getIp();
	animateElementsOnScroll(
		".fifth-step-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2, .fifth-step-form-wrapper, .privacy-policy, .btn-wrapper",
	);
});
</script>

<style lang="scss" scoped>
.fifth-step-wrapper {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;

	h2 {
		font-style: normal;
		font-weight: 600;
		max-width: 800px;
		margin: 0 auto 48px;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}

	.fifth-step-form-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		position: relative;

		.changed-my-mind {
			width: 100%;
			text-align: right;
			margin-top: 8px;
			font-size: 12px;
			color: $second-app-color;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}

		h3 {
			text-align: left;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			max-width: 800px;
			margin: 0 auto 24px;
		}

		textarea {
			// margin-bottom: 0 !important;
			margin: 24px 0;
			height: 200px !important;
			resize: none;
			padding-left: 12px;
			padding-top: 12px;
			text-indent: 0;

			&::placeholder {
				font-family: Arial, sans-serif;
				line-height: 22px;
			}
		}

		input,
		textarea {
			border-radius: 11px;
			border: 1px solid;
			border-color: $section-color;
			width: 100%;
			flex-grow: 1;
			color: var(--vt-c-white);
			background-color: transparent;
			min-width: 0;
			z-index: 2;
			height: 44px;

			&::placeholder {
				color: $text-placeholder;
				opacity: 1;
			}

			&:focus {
				background-color: transparent;
			}
		}

		.contact-options-wrapper {
			display: flex;

			.item-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				min-height: 100%;
				box-sizing: border-box;
				width: 50%;
				max-width: 98%;
				padding: 0 48px;
				z-index: 2;
				cursor: pointer;

				.imgwrap {
					margin-bottom: 2rem;
					height: 50px;
					aspect-ratio: 1 / 1;
					text-align: center;

					:deep() {
						svg {
							height: 100%;
							path {
								fill: $second-app-color;
							}
						}
					}
				}

				h3 {
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					height: 40px;
				}

				&:hover {
					.imgwrap {
						:deep() {
							svg {
								transform: scale(1.2);
								transition: transform 0.3s ease-in-out;
							}
						}
					}
				}
			}
		}
	}

	.privacy-policy {
		text-align: center;
		font-style: italic;
		font-weight: 400;
		max-width: 400px;
		margin: 24px auto 0;
		color: $text-placeholder;
		font-size: 12px;
	}

	.btn-wrapper {
		display: flex;
		justify-content: center;
		z-index: 2;
		position: relative;
		margin-bottom: 48px;
		.btn {
			margin: 26px 0 12px;
			width: 200px;

			&.btn-next {
				background: $app-color;
			}
		}

		.error-msg {
			position: absolute;
			top: 110%;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
			text-align: center;
			font-weight: 700;
			max-width: 400px;
			margin: 0 auto;
			color: rgb(207, 1, 1);
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 768px) {
	.fifth-step-wrapper {
		h2 {
			font-size: 42px;
			line-height: 58px;
		}

		.fifth-step-form-wrapper {
			h3 {
				font-size: 25px;
			}
		}

		.privacy-policy {
			font-size: 14px;
		}
	}
}
</style>
