<template>
	<div class="projects-page-outer-wrapper">
		<div class="projects-page-inner-wrapper">
			<h2>Find out more about our projects!</h2>
			<h3>
				Feel free to discover our projects and learn more about some of our
				projects. We cover a wide range of niches and can help you build your
				product in a safe, professional, and efficient way.
			</h3>
		</div>
	</div>
	<div class="single-project-wrapper-outer">
		<div
			v-for="project in projects"
			:key="project.title"
			class="single-project-wrapper-inner"
		>
			<project-section-template :class="[project.class]" :data="project" />
		</div>
		<bottom-section />
	</div>
</template>

<script setup>
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import vizim from "@/assets/images/upgraded/vizim.png";
// import cplMain from "@/assets/images/upgraded/cpl-main.png";
import cplMainDesk from "@/assets/images/upgraded/cpl-main-desk.png";
import cplBadge from "@/assets/images/upgraded/cpl-icon.svg";
import cplBcg from "@/assets/images/upgraded/cpl-bcg.svg";
import miMain from "@/assets/images/upgraded/xiaomimain.png";
import mibadge from "@/assets/images/upgraded/xiaomi-icon.svg";
// import marsMain from "@/assets/images/upgraded/marsmain.png";
import marsMainDesk from "@/assets/images/upgraded/marsmaindesk.png";
import marsBadge from "@/assets/images/upgraded/mars-icon.svg";
import marsBcg from "@/assets/images/upgraded/mars-bcg.svg";
import mojoMain from "@/assets/images/upgraded/mojo-main.png";
import mojoBadge from "@/assets/images/upgraded/mojo-icon.svg";
// import berzaMain from "@/assets/images/upgraded/berzamain.png";
import berzaMainDesk from "@/assets/images/upgraded/berzamaindesk.png";
import berzaBadge from "@/assets/images/upgraded/eberzalogo.svg";
import berzaBcg from "@/assets/images/upgraded/eberza-bcg.svg";
import restedMain from "@/assets/images/upgraded/berestedmain.png";
import restedBadge from "@/assets/images/upgraded/berested-icon.svg";
// import vizimMain from "@/assets/images/upgraded/vizimmain.png";
import vizimMainDesk from "@/assets/images/upgraded/vizimmaindesk.png";
import vizimBadge from "@/assets/images/upgraded/vizim-icon.svg";
import vizimBcg from "@/assets/images/upgraded/vizim-bcg.svg";
import omniMain from "@/assets/images/upgraded/omnimain.png";
import omniBadge from "@/assets/images/upgraded/omni-icon.svg";
import fiskalkoMain from "@/assets/images/upgraded/fiskalko-main.png";
import fiskalkoBadge from "@/assets/images/upgraded/fiskalko-icon.svg";
// import fwMain from "@/assets/images/upgraded/fairweymain.png";
import fwMainDesk from "@/assets/images/upgraded/fairweymaindesk.png";
import fwBadge from "@/assets/images/upgraded/fairwey-icon.svg";
import fwBcg from "@/assets/images/upgraded/fairwey-bcg.svg";
import caseMain from "@/assets/images/upgraded/case3dmain.png";
import caseBadge from "@/assets/images/upgraded/case3d-icon.svg";
import autoMainDesk from "@/assets/images/upgraded/autopromaindesk.png";
// import autoMain from "@/assets/images/upgraded/autopromain.png";
import autoBadge from "@/assets/images/upgraded/autopro-icon.svg";
import autoBcg from "@/assets/images/upgraded/autopro-bcg.svg";

onMounted(() => {
	animateElementsOnScroll(
		".single-project-wrapper-outer",
		"slideFromBot 1s 0s forwards",
		" .section-template",
	);
	animateElementsOnScroll(
		".projects-page-inner-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2 , h3",
	);
});

const projects = ref([
	{
		isBlack: true,
		title:
			"Moving the limits of Home Care Experience with a Inventive Mobile App",
		// secondTitle: "VIZIM Homecare Application",
		sub: "VIZIM Homecare Application",
		class: "first",
		paragraphs: [
			`Introducing the VIZIM Homecare Application—your all-in-one solution for fast, efficient home care services and seamless appointment scheduling. Effortlessly connect and collaborate with caregivers and clients, without any complicated setups or stress.`,
			`Get started instantly with our intuitive, user-friendly interface available on both mobile devices for clients and web platforms for healthcare professionals—requiring zero training.`,
			`Handle transactions effortlessly with built-in online payment options, and enjoy the benefits of our loyalty program that rewards you with every purchase.`,
		],
		mainImgUrl: vizim,
		badgeImgUrl: "",
	},
	{
		isBlack: false,
		title: "Worker Rostering and Compliance at Scale",
		sub: "CPL Healthcare",
		paragraphs: [
			`Software using advanced algorithms for workforce compliance management, rostering, and attendance, offering full and complete service to agency staff in the healthcare sector in Ireland. The system facilitates and streamlines the daily processes of work in compliance management, job notifications, and more.`,
		],
		mainImgUrl: cplMainDesk,
		// desktopImgUrl: cplMain,
		badgeImgUrl: cplBadge,
		bcgImageUrl: cplBcg,
	},
	{
		isBlack: true,
		isBlackBgRight: true,
		title: "Tailored Product Advertising",
		sub: "Xiaomi",
		paragraphs: [
			`Working with one of the largest manufacturers of consumer electronics and related software, home appliances, and household items to bring their promotional websites and gift sites across Serbia - built to handle a large number of visitors.`,
		],
		mainImgUrl: miMain,
		badgeImgUrl: mibadge,
	},
	{
		isBlack: false,
		title: "Rapid Web Building Tools",
		sub: "MARS Engine",
		paragraphs: [
			`The only production-ready, lightning-fast development platform that you can access from your browser. Start and scale your production projects with just one click. No server setup, no DevOps, no hassle.`,

			`Forget about spending hours on server and environment setup. With MARS, you are ready to start development in mere seconds, where all server instances you need are set with just the "Create new project" button.`,
		],
		mainImgUrl: marsMainDesk,
		// mainImgUrl: marsMain,
		badgeImgUrl: marsBadge,
		bcgImageUrl: marsBcg,
	},
	{
		isBlack: true,
		isBlackBgRight: true,
		title: "AI-Powered Marketing Solutions",
		sub: "MojoAI",
		paragraphs: [
			`Next-generation AI solutions combined with marketing. Serving only relevant ads is a new vision for a global economy. This software manages a lot of facial recognition data and combines it with the next-generation AI algorithms in real-time!`,
		],
		mainImgUrl: mojoMain,
		badgeImgUrl: mojoBadge,
	},
	{
		isBlack: false,
		title: "Tailored eCommerce",
		sub: "eBerza",
		paragraphs: [
			`Disrupting e-commerce by empowering consumers to purchase in bulk. This solves multiple problems at once: Manufacturers will sell more and have less in stock, consumers will save money while optimizing transport and saving the environment.`,
		],
		// mainImgUrl: berzaMain,
		mainImgUrl: berzaMainDesk,
		badgeImgUrl: berzaBadge,
		bcgImageUrl: berzaBcg,
	},
	{
		isBlack: true,
		isBlackBgRight: true,
		title: "eCommerce Platforms",
		sub: "BeRested",
		paragraphs: [
			`This eCommerce site is built on the MARS eCommerce solutions platform. It enables scalability, flexibility, and resilience. It also combines the latest UX/UI solutions for the most intuitive experience for the end user.`,
		],
		mainImgUrl: restedMain,
		badgeImgUrl: restedBadge,
	},
	{
		isBlack: false,
		title: "Powerful Data Visualization",
		sub: "Vizim Doctors",
		paragraphs: [
			`An innovative solution for visualization of various healthcare parameters for patients created for one of the largest private healthcare providers in Serbia. The solution allows healthcare workers to combine these insights to provide guidelines for a long and happy life.`,
		],
		mainImgUrl: vizimMainDesk,
		// mainImgUrl: vizimMain,
		badgeImgUrl: vizimBadge,
		bcgImageUrl: vizimBcg,
	},
	{
		isBlack: true,
		isBlackBgRight: true,
		title: "Comprehensive Organizational Solutions",
		sub: "OmniTasker",
		paragraphs: [
			`A lean and mean scalable task-organizing machine. It combines many functionalities offered by other platforms but is made simple and efficient for the end user. The most prominent feature is viewing the same tasks in hierarchical and kanban views, which leads to better productivity in less time.`,
		],
		mainImgUrl: omniMain,
		badgeImgUrl: omniBadge,
	},
	{
		isBlack: false,
		title: "Connecting Multiple Industries",
		sub: "AutoPRO",
		paragraphs: [
			`AutoPRO is an all-in-one software solution that enables a more user-friendly and streamlined vehicle registration, insurance, and ownership transfer process for clients. Offering automated generation and printing of the relevant documents necessary for purchase and sale, AutoPRO facilitates the process of searching and handling vehicle information. Additionally, it provides a better experience for making vehicle technical inspection bookings.`,
		],
		// mainImgUrl: autoMain,
		mainImgUrl: autoMainDesk,
		badgeImgUrl: autoBadge,
		bcgImageUrl: autoBcg,
	},
	{
		isBlack: true,
		title: "Powerful SaaS Solutions",
		sub: "eFiskalko",
		paragraphs: [
			`A unifying API solution for e-commerce portals to connect with the Serbian Tax Administration. This solution enables simple integration with all existing programming languages. It is scalable and intended for small companies to enterprises as well.`,
		],
		mainImgUrl: fiskalkoMain,
		badgeImgUrl: fiskalkoBadge,
	},
	{
		isBlack: false,
		title: "Streamlining Insurance",
		sub: "Fairwey",
		paragraphs: [
			`Software that speeds up the insurance purchasing and selling process, providing benefits for both consumers and agents. The system facilitates the process of signing insurance policy contracts, helping consumers assess their coverage needs, and quickly shopping the market with real quotes from real local agents created specifically for the consumer's specified needs.`,
		],
		// mainImgUrl: fwMain,
		mainImgUrl: fwMainDesk,
		badgeImgUrl: fwBadge,
		bcgImageUrl: fwBcg,
	},
	{
		isBlack: true,
		isBlackBgRight: true,
		title: "Ultimate Visual Experience",
		sub: "Case3D",
		paragraphs: [
			`Creating a better visual experience for prospective customers when choosing their ideal estate. Users are able to navigate on the map and select and view their preferred building and apartment. Case3D offers a well-designed system and an appealing visual solution for companies operating in the real estate sector.`,
		],
		mainImgUrl: caseMain,
		badgeImgUrl: caseBadge,
	},
]);
</script>

<style lang="scss" scoped>
.projects-page-outer-wrapper {
	padding: ($top-page-nav-height + 40px) 20px 0 20px;

	// overflow: hidden;

	.projects-page-inner-wrapper {
		width: 100%;
		max-width: 1500px;
		margin: 0 auto;

		h2 {
			font-size: 54px;
			font-style: normal;
			font-weight: 600;
			line-height: 48px;
			text-align: center;
			margin-bottom: 48px;
		}

		h3 {
			text-align: center;
			font-size: 25px;
			font-style: normal;
			font-weight: 400;

			// color: $app-color;
			color: $second-app-color;
		}
	}

	@media screen and (width >= 768px) {
		padding: ($top-page-nav-height + 200px) 20px 0 20px;
	}
}

.single-project-wrapper-outer {
	// padding: 56px 20px;
	// &:not(:first-child) {
	// 	overflow-x: hidden;
	// }

	.single-project-wrapper-inner {
		width: 100%;
	}

	@media screen and (width >=768px) {
		.single-project-wrapper-inner {
			height: 1080px;
			min-height: 1080px;

			.section-template {
				height: 100%;
			}
		}
	}
}

.single-project-wrapper-outer:nth-child(even) {
	background: #f9f9fa;
}

@media screen and (width <= 768px) {
	.projects-page-outer-wrapper {
		.projects-page-inner-wrapper {
			h2 {
				text-align: center;
				font-size: 32px;
				line-height: 40px;
			}

			h3 {
				font-size: 15px;
			}
		}
	}
}
</style>
